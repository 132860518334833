import React, { createContext, useState, useContext } from 'react';
import userService from 'services/userService';

const AuthContext = createContext();


const AuthProvider = ({ children }) => {
    const [isLoggedIn, setIsLoggedIn] = useState(false);
    const [error, setError] = useState({});
    const [user, setUser] = useState({});
    

    const login = async (email, password) => {
        const form = {};
        form.email = email;
        form.password = password;

        // Lógica para realizar o login (verificar credenciais, por exemplo)
        const resp = await userService.login(form);
        console.log('aqui', resp);
        if (!resp?.email) {
            setError({ message: 'Usuário não encontrado ou senha incorreta', status: 401 });
            setTimeout(() => {
                setError({});
            }, 5000);
        } else if (resp == 501) {
            setError({ message: 'Senha incorreta!', status: 501 });
            setTimeout(() => {
                setError({});
            }, 5000);
        } else if (resp?.id) {
            setError({ message: 'Login feito com sucesso!', status: 201 });
            setTimeout(() => {
                setIsLoggedIn(true);
                localStorage.setItem('token', resp.token);
                localStorage.setItem('user', JSON.stringify(resp));
                setUser(resp.user);
                setError({});
                window.location.href = "/";
            }, 2000);

        }
        return resp;

    };
    
    const setlocalUser = () => {
        const user = JSON.parse(localStorage.getItem('user'));
        user?.id && setUser(user);
    }

    const logout = () => {
        // Lógica para realizar o logout
        setIsLoggedIn(false);
    };

    return (
        <AuthContext.Provider
            value={{
                error,
                isLoggedIn,
                user,
                setlocalUser,
                login,
                logout,
            }}>
            {children}
        </AuthContext.Provider>
    );
};

const useAuth = () => useContext(AuthContext);

export { AuthProvider, useAuth };
