import React from "react";
import { Container, Row, Modal, ModalHeader, ModalBody, ModalFooter, Button } from "reactstrap";

function ModalConfirm(props) {
  return (
    <Modal isOpen={props.isOpen} toggle={props.toggle} className="modal-dialog-centered">
      <ModalHeader toggle={props.toggle}>Excluir registro</ModalHeader>
      <ModalBody>
        Tem certeza que deseja excluir esse registro?
      </ModalBody>
      <ModalFooter>
        <Button color="primary" onClick={()=> props.toggle('sim')}>Sim</Button>{' '}
        <Button color="secondary" onClick={props.toggle}>Cancelar</Button>
      </ModalFooter>
    </Modal>
  );
}

export default ModalConfirm;
