import api from "./api";


// Definindo o serviço do usuário
const userService = {

  list: async () => {
    try {
      const response = await api.get(`/user/list/all`);
      return response.data;
    } catch (error) {
      console.error('Erro ao listar usuários:', error);
      throw error;
    }
  },

  search: async (partialName) => {
    try {
      const response = await api.get(`/user/search/${partialName}`);
      return response.data;
    } catch (error) {
      console.error('Erro ao listar usuários:', error);
      throw error;
    }
  },

  // Atualizar um usuário existente
  update: async (id, data) => {
    try {
      const response = await api.put(`/user/update/${id}`, data);
      return response;
    } catch (error) {
      console.error('Erro ao atualizar usuário:', error);
      throw error;
    }
  },

  login: async (form) => {
    try {
      const response = await api.post(`/auth/login`, form);
      return response.data;
    } catch (error) {
      console.error('Erro ao fazer login:', error.request.status);
      return error.request.status;
      //throw error;
    }
  },

  // Criar um novo usuário
  create: async (data) => {
    try {
      const response = await api.post(`/user/register`, data);
      return response.data;
    } catch (error) {
      console.error('Erro ao criar usuário:', error);
      throw error;
    }
  },

  // Deletar um usuário existente
  delete: async (id) => {
    try {
      const response = await api.delete(`/user/${id}`);
      return response.data;
    } catch (error) {
      console.error('Erro ao deletar usuário:', error);
      throw error;
    }
  },
};

export default userService;