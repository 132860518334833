import React, { useState, useEffect } from "react";

// reactstrap components
import {
  Button,
  Card,
  CardHeader,
  CardBody,
  CardTitle,
  FormGroup,
  Form,
  Input,
  Row,
  Col,
  Label
} from "reactstrap";

import NotificationAlert from "react-notification-alert";

import defaultService from "../../services/defaultService";
import ModalConfirm from "components/ModalConfirm";
import { useNavigate } from "react-router-dom";

const table = "socios";
function CadastroSociosScreen({ nameRoute }) {

  const navigate = useNavigate();

  const [isLoading, setIsLoading] = useState(false);
  const [currentItem, setCurrentItem] = useState({});
  const [localUser, setLocalUser] = useState({});
  const notificationAlert = React.useRef();


  useEffect(() => {

  }, []);

  const onSubmit = async (e) => {
    e.preventDefault();
    setIsLoading(true);
    const form = {};

    form.table = table;

    for (let index = 0; index < e.target.length; index++) {
      if (e.target[index].name)
        form[e.target[index].name] = e.target[index].value;
    }

    const resp = await defaultService.create(form);

    if (resp?.status == 200) {
      notify("tr", 2, 'Cadastro feito com sucesso!')
      setTimeout(() => {
        navigate('/login');
      }, 2000);
    } else {
      notify("tr", 3, 'Erro ao cadastrar!')
      setIsLoading(false);
    }

  }

  const notify = (place, color, message) => {
    var type;
    switch (color) {
      case 1:
        type = "primary";
        break;
      case 2:
        type = "success";
        break;
      case 3:
        type = "danger";
        break;
      case 4:
        type = "warning";
        break;
      case 5:
        type = "info";
        break;
      default:
        break;
    }
    var options = {};
    options = {
      place: place,
      message: (
        <div><div>{message}</div></div>
      ),
      type: type,
      icon: "nc-icon nc-bell-55",
      autoDismiss: 7,
    };
    notificationAlert.current.notificationAlert(options);
  };

  return (
    <>
      <Row className="content justify-content-center mt-3">
        <NotificationAlert ref={notificationAlert} />
        <Col md="6">

          <Card className="card-user m-3" >
            <CardHeader>
              <CardTitle tag="h5">Cadastro de socios</CardTitle>
            </CardHeader>
            <CardBody>
              <Form onSubmit={onSubmit} datatype="multipart/form-data">
                <Input type="hidden" name="id" defaultValue={currentItem?.id} />

                <Row>
                  <Col className="pr-1" md="12">
                    <FormGroup>
                      <label>Nome</label>
                      <Input
                        defaultValue={currentItem?.nome}
                        type="text"
                        name="nome"
                      />
                    </FormGroup>
                  </Col>

                  <Col className="pr-1" md="12">
                    <FormGroup>
                      <label>Email</label>
                      <Input
                        defaultValue={currentItem?.email}
                        type="email"
                        name="email"
                      />
                    </FormGroup>
                  </Col>

                  <Col className="pr-1" md="12">
                    <FormGroup>
                      <label htmlFor="exampleInputEmail1">
                        Foto
                      </label>
                      <Input
                        defaultValue={currentItem?.foto}
                        type="file"
                        name="foto"
                      />
                    </FormGroup>
                  </Col>

                  <Col md="6">
                    <FormGroup>
                      <label>CPF</label>
                      <Input
                        defaultValue={currentItem?.cpf}
                        placeholder=""
                        type="text"
                        name="cpf"
                        required
                      />
                    </FormGroup>
                  </Col>
                  <Col md="6">
                    <FormGroup>
                      <label>RG</label>
                      <Input
                        defaultValue={currentItem?.rg}
                        placeholder=""
                        type="text"
                        name="rg"
                        required
                      />
                    </FormGroup>
                  </Col>
                  <Col md="6">
                    <FormGroup>
                      <label>Telefone</label>
                      <Input
                        defaultValue={currentItem?.telefone}
                        placeholder=""
                        type="text"
                        name="telefone"
                      />
                    </FormGroup>
                  </Col>
                  <Col md="6">
                    <FormGroup>
                      <label>Telefone para contato</label>
                      <Input
                        defaultValue={currentItem?.telefone_contato}
                        placeholder=""
                        type="text"
                        name="telefone_contato"
                      />
                    </FormGroup>
                  </Col>

                  <Col md="6">
                    <FormGroup>
                      <label>Senha</label>
                      <Input
                        defaultValue={currentItem?.senha}
                        placeholder=""
                        type="text"
                        name="senha"
                      />
                    </FormGroup>
                  </Col>
                  
                  <input type="hidden" name="username" defaultValue="" />
                  <input type="hidden" name="tipo_acesso" defaultValue="Sindicalizado" />
                  <input type="hidden" name="numero_socio" defaultValue="" />
                  <input type="hidden" name="adimplente" defaultValue="Não" />
                </Row>

                <Row>
                  <div className="update ml-auto mr-auto">
                    <Button
                      className="btn-round"
                      color="primary"
                      type="submit"
                      disabled={isLoading}
                    >
                      {currentItem.id ? "Atualizar" : "Criar"}
                    </Button>
                  </div>
                </Row>
              </Form>
            </CardBody>
          </Card>
        </Col>
      </Row>
    </>
  );
}

export default CadastroSociosScreen;
