
// O reducer
const setUserReducer = (state, action) => {
  switch (action.type) {
    case 'set':
      return action.payload; 
    case 'update':
      return state ;
    default:
      return state;
  }
};

export { setUserReducer };