/*!

=========================================================
* Paper Dashboard React - v1.3.2
=========================================================

* Product Page: https://www.creative-tim.com/product/paper-dashboard-react
* Copyright 2023 Creative Tim (https://www.creative-tim.com)

* Licensed under MIT (https://github.com/creativetimofficial/paper-dashboard-react/blob/main/LICENSE.md)

* Coded by Creative Tim

=========================================================

* The above copyright notice and this permission notice shall be included in all copies or substantial portions of the Software.

*/
import Dashboard from "views/Dashboard.js";
import Notifications from "views/Notifications.js";
import Icons from "views/Icons.js";
import Typography from "views/Typography.js";
import TableList from "views/Tables.js";
import Maps from "views/Map.js";
import UserPage from "views/User.js";
import UpgradeToPro from "views/Upgrade.js";
import DocumentosScreen from "views/DocumentosScreen";
import AdministradoresScreen from "views/AdministradoresScreen";
import NoticiasScreen from "views/NoticiasScreen";
import SociosScreen from "views/SociosScreen";
import VideoChamadaScreen from "views/VideoChamadaScreen";
import EmpresasScreen from "views/EmpresasScreen";
import CarteiraScreen from "views/CarteiraScreen";
import AgendamentoScreen from "views/AgendamentoScreen";
import PerfilScreen from "views/PerfilScreen";
import VerNoticiasScreen from "views/VerNoticiasScreen";
import VerAssembleiasScreen from "views/VerAssembleiasScreen";


var routes = [
  {
    path: "/dashboard",
    name: "Dashboard",
    icon: "nc-icon nc-bank",
    component: <Dashboard />,
    layout: "/admin",
    notAllowed: []
  },

  {
    path: "/adminitradores",
    name: "Administradores",
    icon: "nc-icon nc-hat-3",
    component: <AdministradoresScreen />,
    layout: "/admin",
    notAllowed: ['Sindicalizado']
  },

  /*{
    path: "/empresas",
    name: "Empresas",
    icon: "nc-icon nc-tv-2",
    component: <EmpresasScreen />,
    layout: "/admin",
    notAllowed: ['Sindicalizado']
  },*/

  {
    path: "/noticias",
    name: "Notícias",
    icon: "nc-icon nc-paper",
    component: <NoticiasScreen />,
    layout: "/admin",
    notAllowed: ["Sindicalizado"]
  },

  {
    path: "/socios",
    name: "Sindicalizados / Sócios",
    icon: "nc-icon nc-single-02",
    component: <SociosScreen />,
    layout: "/admin",
    notAllowed: ['Sindicalizado']
  },

  {
    path: "/documentos",
    name: "Documentos",
    icon: "nc-icon nc-single-copy-04",
    component: <DocumentosScreen />,
    layout: "/admin",
    notAllowed: []
  },

  {
    path: "/carteira",
    name: "Carteirinha Digital",
    icon: "nc-icon nc-single-copy-04",
    component: <CarteiraScreen />,
    layout: "/admin",
    notAllowed: ['Sindicalizado']
  },

  {
    path: "/agendamentos",
    name: "Calendário",
    icon: "nc-icon nc-single-copy-04",
    component: <AgendamentoScreen />,
    layout: "/admin",
    notAllowed: []
  },

 /* {
    path: "/assembleias",
    name: "Assembléias e Reuniões",
    icon: "nc-icon nc-tv-2",
    component: <VideoChamadaScreen />,
    layout: "/admin",
    notAllowed: ['Sindicalizado']
  },*/

  {
    path: "/perfil",
    name: "Perfil",
    icon: "nc-icon nc-single-02",
    component: <PerfilScreen />,
    layout: "/admin",
    notAllowed: []
  },

  {
    path: "/news",
    name: "Ver Notícias",
    icon: "nc-icon nc-paper",
    component: <VerNoticiasScreen />,
    layout: "/admin",
    notAllowed: []
  },

  {
    path: "/reunioes",
    name: "Ver Assembleias",
    icon: "nc-icon nc-tv-2",
    component: <VerAssembleiasScreen />,
    layout: "/admin",
    notAllowed: []
  },


  /*{
    path: "/icons",
    name: "Icons",
    icon: "nc-icon nc-diamond",
    component: <Icons />,
    layout: "/admin",
  },
  {
    path: "/documents",
    name: "Documentos",
    icon: "nc-icon nc-single-copy-04",
    component: <Documents />,
    layout: "/admin",
  },

  {
    path: "/send-documents",
    name: "Enviar Documentos",
    icon: "nc-icon nc-share-66",
    component: <SendDocuments />,
    layout: "/admin",
  },*/

  /***************************************************** */
  /*{
    path: "/icons",
    name: "Icons",
    icon: "nc-icon nc-diamond",
    component: <Icons />,
    layout: "/admin",
  },
  {
    path: "/maps",
    name: "Maps",
    icon: "nc-icon nc-pin-3",
    component: <Maps />,
    layout: "/admin",
  },
  {
    path: "/notifications",
    name: "Notifications",
    icon: "nc-icon nc-bell-55",
    component: <Notifications />,
    layout: "/admin",
  },
  {
    path: "/user-page",
    name: "User Profile",
    icon: "nc-icon nc-single-02",
    component: <UserPage />,
    layout: "/admin",
  },
  {
    path: "/tables",
    name: "Table List",
    icon: "nc-icon nc-tile-56",
    component: <TableList />,
    layout: "/admin",
  },
  {
    path: "/typography",
    name: "Typography",
    icon: "nc-icon nc-caps-small",
    component: <Typography />,
    layout: "/admin",
  },
  {
    pro: true,
    path: "/upgrade",
    name: "Upgrade to PRO",
    icon: "nc-icon nc-spaceship",
    component: <UpgradeToPro />,
    layout: "/admin",
  },*/
];
export default routes;
