import React, { useEffect, useState } from "react";
import { Card, CardHeader, CardBody, Row, Col, Button, Form, Input, CardTitle, FormGroup, Alert } from "reactstrap";
import { useNavigate } from 'react-router-dom';

import userService from "services/userService";
import { useAuth } from 'contexts/AuthContext';


function LoginAdmin() {

  const { login, error, isLoggedIn } = useAuth();
  let navigate = useNavigate();
  
  useEffect(() => {
    const user = JSON.parse(localStorage.getItem('user'));
    const token = localStorage.getItem('token');

    if(user?.id && token) {
      navigate('/admin/dashboard');
    }
  },[]);

  const onSubmit = async (e) => {
    e.preventDefault();

    const email = e.target[0].value;
    const password = e.target[1].value;

    const resp = await login(email, password);
    console.log('resp', resp);
    if(resp?.token) {
      
      //navigate('/admin/dashboard');
    }
    
  }

  return (
    <div className="content" style={{ backgroundColor: "#f3f3f3", height: window.innerHeight - 15 }}>
      <Col md="10" style={{ paddingLeft: "35%", paddingTop: "10%" }}>

        <Card className="card-user m-3" style={{ maxWidth: "500px", alignSelf: "center" }}>
          <Col className="mt-3">
            {error?.status && error?.status != 201 && <Alert color="warning">
              {error?.message}
            </Alert>}
            {error?.status == 201 && <Alert color="success">
              Login feito com sucesso!
            </Alert>}
          </Col>
          <br />
          <CardHeader>
            <CardTitle tag="h5">Login de Administrador</CardTitle>
          </CardHeader>
          <CardBody>
            <Form onSubmit={onSubmit}>
              <Row>
                <Col className="m-1" md="12">
                  <FormGroup>
                    <label htmlFor="exampleInputEmail1">
                      Email
                    </label>
                    <Input
                      placeholder="Email"
                      type="email" name="email"
                    />
                  </FormGroup>
                </Col>
              </Row>
              <Row>
                <Col className="m-1" md="12">
                  <FormGroup>
                    <label>Senha</label>
                    <Input
                      placeholder="Senha"
                      type="password"
                      name="password"
                    />
                  </FormGroup>
                </Col>
              </Row>
              <Row>
                <div className="update ml-auto mr-auto">
                  <Button
                    className="btn-round"
                    color="primary"
                    type="submit"
                  >
                    Login
                  </Button>
                </div>
              </Row>
            </Form>
          </CardBody>
        </Card>
      </Col>
    </div>
  );
}

export default LoginAdmin;
